@import "style";

.AssignmentAnswerList {
  width: 100%;
  min-height: 100%;
  background: $color-light-gray;
  z-index: 2;
  padding-top: 1px;
  padding-right: 2px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.AssignmentAnswerList__content {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.AssignmentAnswerList__pagination {
  margin-bottom: 10px;
}

.AssignmentAnswerList__title {
  color: $color-purple;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 1rem;
}

.AssignmentAnswerList__assignmentTitle {
  font-size: 2.25rem;
  margin-bottom: 20px;
}

.AssignmentAnswerList__item {
  margin-bottom: 20px;
}

@media screen and (max-width: $screen-dimension-tablet) {
  .AssignmentAnswerList__assignmentTitle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: $screen-dimension-mobile) {
  .AssignmentAnswerList {
    width: calc(100% + 10px);
    margin-left: -5px;
    margin-right: -5px;
  }

  .AssignmentAnswerList__assignmentTitle {
    margin-bottom: 48px;
  }
}
