@import "../../../../style/index";

.AnswerCurrentQuestion {
  width: 100%;
  margin: auto;
  height: 100%;
}
.CurrentAssignmentPage__main__center {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: $screen-dimension-tablet) {
  .AnswerCurrentQuestion {
    width: 100%;
  }
}

@media screen and (max-width: $screen-dimension-mobile-425) {
  .AnswerCurrentQuestion {
    margin: 0;
  }
}
