.AssignmentDistributeList {
  padding-top: 10px;
  height: 100%;
  overflow: auto;
}
.AssignmentDistributeList .css-1y8mk30-SkeletonTheme {
  width: 100%;
  height: 70px;
}
.AssignmentDistributeList .css-1y8mk30-SkeletonTheme .react-loading-skeleton {
  height: 60px !important;
}