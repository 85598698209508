@import "../../../style";

.CurrentAssignmentPage {
  position: absolute; // FIXME this needed because of src/components/App/App.tsx
  top: 0; // add logic for conditional rendering of Sidebars and Header, remove Header and
  width: 100vw; // Sidebar from children, then remove this code
  height: 100vh;
  z-index: 20;
  background: $color-white;

  display: flex;
  flex-direction: column;
  &__mybreadcrumbs {
    position: absolute;
    top: 25px;
    z-index: 99;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    .CurrentAssignmentPage__button {

    }
  }
}

.CurrentAssignmentPage__content {
  position: relative;
  height: calc(100vh - #{$header-height}); // FIXME see issue above
}

.CurrentAssignmentPage__container {
  display: flex;
  flex-direction: column;
  //height: calc(100vh - #{$header-height});
  height: 100%;
  overflow-y: auto;
}
.CurrentAssignmentPage__main {
  @include flex-center;
  flex: 10 0;
  width: 100%;
  position: relative;
  min-height: fit-content;
  height: auto;
  &.questionBody {
    position: relative;
    .AnswerCurrentQuestion {
      padding: 20px 50px;
    }
    .ButtonNextNew {
      padding: 0px 50px;
      padding-bottom: 40px;
      text-align: center;
    }
    .CurrentAssignmentPage__main__center {
      background: rgb(255, 255, 255);
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0 ,0, 0.1);
      width: 750px;
      padding: 0px;
      height: auto;
      min-height: 400px;
      .CurrentAssignmentPage__navBar {
        position: relative;
        height: auto;
        width: 100%;
        background: transparent;
        display: block;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 10px;
          background: rgb(231, 236, 239);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        .AssignmentOverview {
          padding: 0px;
          &__questions {
            margin-top: 0px;
            display: flex;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between;
            .AssignmentOverview__text {
              overflow: auto;
              text-overflow: initial;
              margin-bottom: 0px;
              padding: 0px;
            }
            .AssignmentOverview__order {
              position: relative;
              top: 0px;
              transform: none;
              background: rgb(151, 151, 151);
              color: white;
              margin: 0px;
              border: 2px solid rgb(151, 151, 151);
              margin-top: 15px;
              &:before {
                content: '';
                height: 6px;
                width: 6px;
                position: absolute;
                top: -15px;
                background: #0a7b24;
                border-radius: 50%;
                left: 50%;
                margin-left: -3px;
              }
            }
            .question_in {
              .AssignmentOverview__order {
                background: #0A7B24;
                color: white;
                border-color: #0A7B24;
              }
            }
          }
        }
      }
    }
    .QuestionPreview {
      &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        pointer-events: none;
        z-index: -1;
        left: 0;
        top: 0;
      }
    }
  }
}

.CurrentAssignmentPage__breadcrumbs {
  flex: 1 0;
  padding-left: $assignment-navigation-width;
  position: fixed;
  bottom: 0;
  background-color: $color-white;
  width: 100%;
}

.CurrentAssignmentPage__navigation {
  display: none;
  height: 50px;
  border-bottom: 1px solid $color-gray;
}

.CurrentAssignmentPage__button {
  background: transparent;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 0 10px 2px;
  border-right: 1px solid $color-gray;
  cursor: pointer;
  background: $color-white;
}

.CurrentAssignmentPage__button_disabled {
  color: rgba($color-text-primary, 0.5);
}

.CurrentAssignmentPage__button_right {
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 2px;
}

.CurrentAssignmentPage__buttonImage {
  @include circle(30px);
  margin-left: 14px;
  margin-right: 14px;
}

.CurrentAssignmentPage__navBar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: $color-light-gray;
  width: $assignment-navigation-width;

  z-index: 1;
}

.CurrentAssignmentPage__overlay {
  background: rgba($color-black, 0.4);
  position: absolute;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  display: none;
}

.CurrentAssignmentPage__notification {
  display: inline-block;
  position: fixed;
  font-size: 1.0625rem;
  right: 20px;
  top: 33px;
  z-index: 4;

  div {
    color: $color-blue-gray;
  }
}

@media screen and (max-width: $screen-dimension-desktop-l) {
  .CurrentAssignmentPage__main {
    padding-left: $assignment-navigation-width;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .CurrentAssignmentPage__main {
    padding-top: 0;
    padding-left: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .CurrentAssignmentPage__navigation {
    top: 0;
    position: sticky;
    display: flex;
    z-index: 1;
  }

  .CurrentAssignmentPage__navBar {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  }

  .CurrentAssignmentPage__navBar_visible {
    transform: translateX(0);
    opacity: 1;
    z-index: 10;
  }

  .CurrentAssignmentPage__overlay {
    display: block;
    top: $header-height-tablet;
  }

  .CurrentAssignmentPage__content {
    height: calc(100vh - #{$header-height-tablet});
  }

  .CurrentAssignmentPage__notification {
    display: none;
  }
}

.CurrentAssignmentPage {
  &__mybreadcrumbs {
    position: absolute;
    top: -55px;
    z-index: 99;
    align-items: center;
    display: flex;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    .breadcrumb {
      padding: 10px;
      height: auto;
      margin: 0px;
      border-top: none;
      .breadcrumbs {
        align-items: center;
        li {
          font-size: 15px;
        }
      }
    }
    .CurrentAssignmentPage__button {
      padding: 0px;
      border-right: none;
      img {
        margin: 0px;
        width: 20px !important;
        height: 20px;
      }
    }
  }
}

.ButtonNextNew {
  padding: 0px 15px;
  button {
    padding: 10px;
    border-radius: 25px;
    color: white;
    background: #0b2541;
    min-width: 200px;
    margin-top: 20px;
    &:disabled {
      opacity: 0.4;
    }
    &:focus {
      outline: black;
      outline-style: auto;
      outline-offset: 2px;
    }
  }
}


@media screen and (max-width: $screen-dimension-mobile-425) {
  .CurrentAssignmentPage__main {
    padding-top: 0;
  }
}

