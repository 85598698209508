.SubmitTeachingPath {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  &__content {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0 ,0, 0.1);
    width: 750px;
    padding: 0px;
    height: auto;
    padding: 50px 70px;
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    z-index: -1;
    left: 0;
    top: 0;
  }
}

.SubmitTeachingPath__title {
  font-size: 2.25rem;
  margin-bottom: 20px;
}

.SubmitTeachingPath__description {
  font-weight: 300;
}

.SubmitTeachingPath__deleteButton {
  background-color: transparent;
  text-decoration: underline;
  margin-top: 10px;
}

.SubmitTeachingPath__button {
  margin-top: 20px;
  border-radius: 30px;
  background: #379b43;
  width: fit-content;
  padding: 5px 20px;
  align-items: center;
  color: #fff;
  font-size: 1.0625rem;
  border: 1px solid #379b43;
  display: flex;
  justify-content: center;
}

.SubmitTeachingPath__buttonImage {
  margin-right: 10px;
}

.submitButton:disabled {
  color: white;
  background-color: #939FA7;
  border: 1px solid #939FA7;
  cursor: default;
}
