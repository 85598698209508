@import "../../style";

.SliderWidget {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 8px;
  &__contentWrapper {
    width: 100%;
    // HACK to make sass use built-in css min() function instead of sass defined
    padding-top: unquote("min(56%, 550px)");
  }
  &__infoWrapper_outside {
    display: none;
  }
  &__titleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    bottom: auto;
    z-index: 5;
  }
  &__content {
    @include absolute-attach(none);
    @include flex-center;
    z-index: 1;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 0;

  }
  &__info {
    position: absolute;
    bottom: 24px;
    left: 21px;
    right: 21px;
    z-index: 2;
  }
  &__title {
    @include text-shrink-multiline(2);
    margin-bottom: 5px;
    color: $color-white;
    font-size: 1.875rem;
  }
}

.SliderWidget_collapsed {
  height: 122px;
}

.SliderWidget__title_oneLine {
  @include text-shrink-multiline(1);
  padding-right: 26px;
}

.SliderWidget__description {
  margin-bottom: 12px;
  font-size: 1.25rem;
  color: $color-white;
  width: 65%;
  font-weight: 300;

  // Needed to make color of text in layout provided from WP white and have needed size
  & * {
    font-size: 1.25rem;
    color: $color-white;
  }
}

.SliderWidget__description_oneLine {
  @include text-shrink-multiline(1);
  padding-right: 80px;
  //margin-bottom: 5px;
}

.SliderWidget__button {
  padding: 9px 23px;
  border-radius: 20px;
  background-color: $color-purple;
  color: $color-white;
  font-size: 1.0625rem;
  &:hover {
    background-color: #af0060;
  }
}
.SliderWidget__slideSwitcher {
  position: absolute;
  bottom: 24px;
  right: 55px;
  z-index: 3;
}

.SliderWidget__slideSwitcher .SliderWidget__content__pause {
  bottom: 0;
  right: -35px;
  pointer-events: none;
}

.SliderWidget__switchButton {
  @include circle(15px);
  border: 1px solid $color-white;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 100ms ease-in-out;
  margin-left: 12px;
  padding: 0.40em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }
}

.SliderWidget__switchButton_active {
  background-color: $color-white;
}

.SliderWidget__content__pauseicon {
  position: absolute;
  width: 100%;
  pointer-events: none;
  height: 100%;
  background-size: cover;
  background-image:url('../../assets/images/button-pause.svg');
}
.SliderWidget__content__pause {
  position: absolute;
  width: 26px;
  height: 26px;
  cursor: pointer;
  bottom: 24px;
  right: 20px;
  z-index: 4;
  border-radius: 50%;
  margin: 0px;
  padding: 0px;
  &:before {
    content: attr(data-pause);
    z-index: 1;
    position: absolute;
    box-shadow: 0 2px 2px #00000021;
    margin-top: 20px;
    width: max-content;
    border-radius: 4px;
    border: 1px solid #596A75;
    padding: 20px;
    cursor: default;
    background: #596A75;
    right: -6px;
    top: -92px;
    pointer-events: none;
    opacity: 0;
    color: white;
    font-size: 1.0625rem;
  }
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #596A75;
    right: -2px;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #596A75;
    border-left: 1px solid #596A75;
    border-bottom: 1px solid #596A75;
    top: -8px;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
  }
  &:hover, &:focus {
    &:before, &:after {
      opacity: 1;
    }
  }
  &.active {
    &:before {
      content: attr(data-play);
    }
    i {
      background-image:url('../../assets/images/button-play.svg');
    }    
  }
}

.SliderWidget__content__pause.opacity .SliderWidget__content__pauseicon {
  opacity: 0;
}

.SliderWidget__content_animated-enter, .SliderWidget__content_animated-exit {
  @include animate(transform, 0.5s)
}

.SliderWidget__content_animated-enter {
  transform: translateX(100%);
}

.SliderWidget__content_animated-enter-active {
  transform: translateX(0);
}

.SliderWidget__content_animated-exit {
  transform: translateX(0);
}

.SliderWidget__content_animated-exit-active {
  transform: translateX(-100%);
}

.SliderWidget__image_animated {
  animation: zoomOut 7s linear;

  @keyframes zoomOut {
    from {
      transform: scale(1.2) translate(50px, 20px);
    }
    to {
      transform: scale(1) translate(0, 0);
    }
  }
}

.SliderWidget__image_animatedCollapsed {
  animation: zoomOut 7s linear;

  @keyframes zoomOut {
    from {
      transform: scale(1.2) translate(10px, 5px);
    }
    to {
      transform: scale(1) translate(0, 0);
    }
  }
}


@media screen and (max-width: $screen-dimension-desktop-l) {
  .SliderWidget__title {
    font-size: 1.5rem;
    margin-bottom: 3px;
  }

  .SliderWidget__description {
    font-size: 1.0625rem;
    margin-bottom: 9px;
  }
}

@media screen and (max-width: $screen-dimension-mobile) {
  .SliderWidget {
    border-radius: 0;
  }

  .SliderWidget_small {
    height: auto;
  }

  .SliderWidget__title {
    @include text-shrink-multiline(4);
    color: $color-text-primary;
    font-size: 1.5rem;
    line-height: 31px;
    margin-bottom: 5px;
  }

  .SliderWidget__description {
    margin-bottom: 18px;
    color: $color-text-primary;
  }

  .SliderWidget__button {
    width: 100%;
  }

  .SliderWidget__infoWrapper_outside {
    padding: 16px 16px 0 16px;
    background-color: $color-light-gray;
    display: block;
  }

  .SliderWidget__infoWrapper_inside {
    display: none;
  }

  .SliderWidget__info {
    position: static;
  }

  .SliderWidget__contentWrapper {
    position: relative;
    overflow: hidden;
  }

  .SliderWidget__slideSwitcher {
    left: 21px;
    display: flex;
    justify-content: center;
  }

  @keyframes zoomOut {
    from {
      transform: scale(1.2) translate(10px, 5px);
    }
    to {
      transform: scale(1) translate(0, 0);
    }
  }
}
