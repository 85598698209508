.imageOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D3D9DE;
  border-radius: 8px;
  padding: 0;
  margin-bottom: 10px;
  background: #D3D9DE;
  z-index: 7;
  cursor: move;
  overflow: hidden;
  &.inputRight {
    border: solid 2px #0a7b24;
    .ImageOption__button {
      border: solid 2px #0a7b24;
    }
  }
  button {
    background: transparent;
    padding: 0px;
    &:focus {
      outline: auto;
    }
  }

  input {
    border: none;
    background: none;
    font-size: 1.0625rem;
    width: calc(100% - 100px);
    padding: 10px 0 10px 10px;
    height: 43px;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
      color: #596A75;
    }
  }

  .handleImage {
    padding: 10px;
    cursor: pointer;
    width: 38px;
    height: 38px;
  }

  .miniImage {
    height: 43px;
    width: 51px;
    object-fit: cover;
  }

  .statusBox {
    display: flex;
    padding-right: 20px;
    button {
      &.status {        
        margin-right: 20px;
      }
    }

    img {
      cursor: pointer;
    }

    .checkStatus {
      width: 30px;
    }
  }

  &.isRight {
    border: 1px solid #0A7B24;
  }

  &.hasErrorOption {
    border: 1px solid #E2017B;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
