@import "style";

.AssignmentsList {
  position: relative;
  height: calc(100% - 60px);
  background: #E7ECEF;
  .gradesChildrenItems {
    margin-top: 15px;
  }

  .assignmentsListHeader {
    width: calc(100% - 40px);
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    border: 1px solid #D3D9DE;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    div {      
      font-size: 1rem;
      font-weight: 500;
      color: #e2017b;
      font-weight: 500;
      cursor: pointer;
      button {
        background: transparent;
        position: relative;
        margin-right: 20px;
        padding: 0px;
        &:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 3px;
        }
        &.selectedTab {
          color: #e2017b;
        }
        &:before {
          content: '';
          background: #e2017b;
          position: absolute;
          width: 35px;
          height: 35px;
          top: 6px;
          left: -1px;
          border-radius: 50%;
          opacity: 0;
        }
        &:hover {
          img {
            filter: grayscale(1)invert(1);
          }
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &__left {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        margin-right: 25px;
        color: #e2017b;
      }
      a {
        color: #0b2541;
        &:focus {
          outline: black;
          outline-style: auto;
          outline-offset: 3px;
        }
      }
    }
    &__right {
      button {
        margin-right: 0px !important;
      }
    }

    img {
      cursor: pointer;
      width: 33px;
      height: 45px;
      position: relative;
      top: 1px;
    }
  }

  .SearchFilter {
    margin-left: 20px;
    margin-right: 20px;
  }
  

  .assignmentsListContainer {
    margin-top: 20px;
    // height: 676px;
    padding: 0 20px 0 20px;
    overflow: auto;

    .skeletonAssignmentItem {
      height: 60px;
      min-height: 60px;
      align-items: center;
      padding-right: 20px;
      margin: 0 0 20px 0;
      border-radius: 5px;
      font-size: 1.0625rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
    }

    .assignmentItem {
      @extend .skeletonAssignmentItem;
      background: #FFF;
      &.selectedItem {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          pointer-events: none;
          border-radius: 5px;
          border: solid 2px;
          left: 0px;
          top: 0px;      
        }
      }

      .itemImg {
        width: 90px;
        height: 60px;
        object-fit: cover;
        border-radius: 5px 0 0 5px;
      }

      .itemLikes {
        height: 34px;
        width: 60px;
        padding: 10px;
        margin: 10px 0 10px 20px;
        background: #E7ECEF;
        border-radius: 5px;
        font-size: 0.75rem;

        img {
          margin-right: 10px;
        }
      }

      .itemTitle {
        margin-left: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .itemSubject {
        margin-left: 20px;
        background: #E7ECEF;
        border: 1px solid #D3D9DE;
        border-radius: 25px;
        padding: 10px 15px;
        font-size: 0.9375rem;
        font-weight: 300;
      }

      .itemNumberOfQuestions {
        margin-left: 20px;
        color: #E2017B;
        font-size: 0.9375rem;
        width: max-content;
      }

      .itemIsSelected {
        margin-left: 20px;

        img {
          height: 30px;
          width: 30px;
          cursor: pointer;
        }
      }
    }
  }

  .noResults {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin-top: 20%;
    font-size: 1.5rem;
  }

  .MyListPagination {
    margin-bottom: 10px;
  }

  .assignmentsListFooter {
    position: fixed;
    bottom: 0;
    width: calc(100% - 500px);
    height: 64px;
    padding: 15px 20px;
    background: #FFF;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .assignmentCounter {
      height: 30px;
      padding: 10px;
      font-size: 0.9375rem;
      background: #FFFFFF;
      border: 1px solid #D3D9DE;
      border-radius: 5px;
    }
  }
}



@media screen and (max-width: $screen-dimension-desktop) {
  .AssignmentsList .assignmentsListFooter {
    width: calc(100% - 400px);
  }
}


@media screen and (device-width: 810px) and (device-height: 1080px) {
  .AssignmentsList .assignmentsListFooter {
      width: calc(100% - 300px);
    }
}
