@import "style";

$second-column-with: 280px;
$second-column-with-desktop-l: 252px;
$second-column-with-desktop: 212px;

.ActivityPage {

}

.ActivityPage__greeting {
  margin-top: -1px;
  margin-left: -2px;
  margin-bottom: 20px;
  font-size: 1.625rem;
  h1 {
    font-size: 1.625rem;
    margin: 0px;
  }
}

.recentActivityNewContent {
  position: relative;
  &__pause {
    position: absolute;
    width: 26px;
    height: 26px;
    cursor: pointer;
    top: 18px;
    right: 55px;
    background: white;
    z-index: 2;
    border-radius: 50%;
    border: solid 2px #0b2541;  
    i {
      position: absolute;
      width: 100%;
      pointer-events: none;
      height: 100%;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 7px;
        border: solid 2px #0b2541;
        top: 5px;
        left: 5px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 7px;
        border: solid 2px #0b2541;
        top: 5px;
        right: 6px;
      }
    }
    &.active {
      i {
        &:after {
          display: none;
        }
        &:before {
          top: 6px;
          left: 8px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 9px;
          border-color: transparent transparent transparent #0b2541;
        }
      }    
    }
  }
}

.ActivityPage__content {
  display: flex;
  margin-right: -2px;
  margin-left: -2px;
}

.ActivityPage__main {
  flex: 1 1 auto;
  padding-right: 19px;
  z-index: 1;
}

.ActivityPage__targetRoleTitle {
  display: flex;
  margin: 10px 0;
}

.ActivityPage__aside {
  width: $second-column-with;
  flex: 0 0 auto;
}

.marginTop {
  margin-top: 42px;
}

.ActivityPage__widget {
  border-radius: 8px;
  background-color: $color-white;
  border: 1px solid $color-dark-gray;
  margin-bottom: 20px;
}

@media screen and (max-width: $screen-dimension-desktop-l) {
  .ActivityPage__greeting {
    display: none;
  }

  .ActivityPage__aside {
    width: $second-column-with-desktop-l;
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  .ActivityPage__aside {
    width: $second-column-with-desktop;
  }
}

@media screen and (max-width: $screen-dimension-tablet-xl) {
  .ActivityPage__content {
    flex-direction: column;
  }

  .ActivityPage__main {
    padding-right: 0;
  }

  .ActivityPage__aside {
    width: auto;
  }

  .marginTop {
    margin-top: 0;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .ActivityPage {
    padding-top: 20px;
  }

  .marginTop {
    margin-top: 0;
  }
}

@media screen and (max-width: $screen-dimension-mobile) {
  .ActivityPage {
    padding-top: 0;
  }

  .marginTop {
    margin-top: 0;
  }

  .ActivityPage__widgetSlider {
    margin-left: -18px;
    margin-right: -16px;
    border-radius: 0;
    border: 0;
    overflow: hidden;
  }

  .ActivityPage__content {
    margin-right: -3px;
  }
}
