@import "style";

.LoginPage {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 2.25rem;

  .loginRightSide {
    height: 100%;
    width: 50%;

    .mobileFormTitle {
      display: none;
    }

    .loginBgImage {
      background: url(../../../assets/images/login-right-side-image.png) no-repeat;
      background-size: cover;
      background-position: center bottom;
      width: 100%;
      height: 100%;
      //min-height: 260px;
      display: flex;
    }
  }
}
@media screen and (max-width: $screen-dimension-tablet-xl) {
  .LoginPage {
    flex-direction: column-reverse;
    margin-top: 92px;
    background-color: #e7ecef;
  }

  .LoginPage .loginRightSide {
    flex-direction: column;
    width: 100%;

    .mobileFormTitle {
      display: flex;
      text-align: center;
      font-size: 1.5rem;
      margin-top: 25px;
      margin-bottom: 15px;
      justify-content: center;
    }
  }
}

@media screen and (device-width: 810px) and (device-height: 1080px) and (orientation:portrait) {
  .LoginPage {
    .loginRightSide {
      .loginBgImage {
        background: url(../../../assets/images/login-right-side-image.png) no-repeat;
        background-size: 100%;
        background-position: center bottom;
        width: 100%;
        height: calc(100% - 170px);
      }
    }
  }
}
