// for color naming use this tool http://chir.ag/projects/name-that-color/#6195ED

$color-purple: #E2017B;
$color-light-gray: #E7ECEF;
$color-gray: #E4E9ED;
$color-dark-gray: #D3D9DE;
$color-blue-gray: #596A75;
$color-white: #FFF;
$color-black: #000;
$color-green: #0A7B24;
$color-dark-green: #065F1B;
$color-sea-green: #2D9A46;
$color-regent-gray: #939fa7;
$color-pale-sky: #6E7C85;

$color-text-primary: #0B2541;
$color-text-secondary: #404041;

$header-height: 78px;
$header-height-tablet: 92px;
$sidebar-width: 211px;
$sidebar-width-tablet: 66px;
$assignment-width: 600px;
$assignment-navigation-width: 230px;

$screen-dimension-desktop-xxl: 1920px;
$screen-dimension-desktop-xl: 1440px;
$screen-dimension-desktop-1569: 1569px;
$screen-dimension-desktop-1369: 1369px;
$screen-dimension-desktop-1366: 1366px; // FIXME rename all dimensions to be like this
$screen-dimension-desktop-l: 1280px;
$screen-dimension-desktop: 1024px;
$screen-dimension-tablet-xl: 960px;
$screen-dimension-tablet-l: 920px;
$screen-dimension-tablet: 870px;
$screen-dimension-mobile: 640px;
$screen-dimension-mobile-425: 425px;
