.PublishingPage {
  margin-top: 77px;
  width: 100%;
  height: calc(100% - 80px);
  background: #ffffff;
  
  .publishingInfo {
    position: fixed;
    overflow: hidden;
    background: #E7ECEF;
    padding: 0;
    overflow: auto;
    height: 100%;
    border-right: 1px solid #d3d9de;
    width: 60%;
  }
}
