.Images {
  .imagesWrapper {
    justify-content: normal;
    margin-bottom: 10px;
    width: 100%;
    overflow: scroll;
    /* height: 240px; */
    height: auto;

    img {
      margin-right: 15px;
      margin-bottom: 10px;
      /* width: calc(33.333% - 10px); */
      width: 100%;
      height: 170px;
      object-fit: cover;
      cursor: zoom-in;
    }
  }

  .centered {
    justify-content: center;
  }
}
