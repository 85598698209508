@import "style";
.ArticlesList {
  position: relative;
  height: calc(100% - 60px);
  background: #E7ECEF;

  .articlesListHeader {
    width: calc(100% - 40px);
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 #d3d9de;
    border: 1px solid #D3D9DE;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    
    div {      
      font-size: 1rem;
      font-weight: 500;
      color: #e2017b;
      font-weight: 500;
      cursor: pointer;
      button {
        background: transparent;
        position: relative;
        &:before {
          content: '';
          background: #e2017b;
          position: absolute;
          width: 35px;
          height: 35px;
          top: 7px;
          left: 5px;
          border-radius: 50%;
          opacity: 0;
        }
        &:hover {
          img {
            filter: grayscale(1)invert(1);
          }
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &__left {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        margin-right: 25px;
        color: #e2017b;
      }
      a {
        color: #0b2541;
      }
    }

    img {
      cursor: pointer;
      width: 33px;
      height: 45px;
      position: relative;
      top: 1px;
    }
  }

  .SearchFilter {
    margin-left: 20px;
    margin-right: 20px;
  }

  .articlesListContainer {
    // height: calc(100% - 60px - 80px - 20px - 60px);
    // height: 676px;
    flex-wrap: wrap;
    height: 100%;
    // justify-content: space-between;
    margin-top: 20px;
    padding: 0 10px 0 10px;
    overflow: auto;
    min-height: 530px;
    .RelatedArticlesCard {
      margin: 10px 13px 10px 0;
      margin-right: 6px;
      margin-left: 6px;
      max-height: 325px;
    }

    .noResults {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 20%;
    }
  }

  .articlesListFooter {
    position: absolute;
    bottom: -60px;
    width: 100%;
    height: 60px;
    padding: 15px 20px;
    background: #FFF;
    font-weight: 300;
    justify-content: space-between;
    z-index: 1;
    .selectedArticles {
      height: 30px;
      padding: 10px;
      font-size: 0.9375rem;
      background: #FFFFFF;
      border: 1px solid #D3D9DE;
      border-radius: 5px;
    }
  }
}
.ArticlesList .articlesListHeader__left a:focus {
  outline: black;
  outline-style: auto;
  outline-offset: 3px;
}
.back-buttonAbs {
  display: flex;
  align-items: center;
  position: fixed;
  top: 31px;
  z-index: 99999999;
  left: 20px;
  font-size: 15px;
  background: white;
  padding: 0px;
  img {
    margin-right: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 31px;
    height: 31px;
    background: #e2017b;
    z-index: -1;
    border-radius: 50%;
    left: -2px;
    top: -2px;
    opacity: 0;
  }
  &:hover {
    img {
      filter: brightness(0)invert(1);
    }
    &:before {
      opacity: 1;
    }
  }
}