.EditTeachingPath {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E7ECEF;
  overflow: auto;
  z-index: 4;

  .AppHeader {
    position: fixed;
    width: 100%;
    z-index: 20;
  }

  .main {
    margin-top: 80px;
    height: calc(100% - 80px);
  }
}
