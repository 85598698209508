
.SelectCoverImage {

    width: 100%;
    /* overflow: auto; */
    height: 100%;
    &__header {
      height: 30%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }
    &__body {      
      height: 70%;
      overflow: scroll;
      .CenterImagenes {
        &.centerMsj {
          text-align: center;
          p {
            font-size: 25px;
          }
        }
      }
    }

    .questions {
      margin-bottom: 40px;
      padding: 0px 20px;
  
      .question {
        margin-bottom: 10px;
        
  
        p {
          width: calc(100% - 25px);
          font-size: 1rem;
          font-weight: 300;
          margin: 0;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
  
        &:last-child {
          font-weight: 500;
        }
  
        .questionNumber {
          font-weight: 500;
          margin-right: 10px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 2px solid #000;
          font-size: 0.625rem;
        }
      }
    }
  
    .title {
      margin: 0 0 10px 0;
      padding: 0px 20px;
      font-weight: 500;
      font-size: 1.0625rem;
    }
  
    .gradesSubjectsContainer {
      margin-bottom: 40px;
  
      .gradesSubjects {
        width: 100%;
        flex-wrap: wrap;
  
        .gradeSubject {
          display: inline-flex;
          padding: 7px 16px 7px 17px;
          font-size: 0.8125rem;
          margin: 0 10px 10px 0;
          background: #0B2541;
          border: 1px solid #071E37;
          border-radius: 25px;
          color: #ffffff;
        }
      }
    }
  }

.coverImageButton {
    text-align: left;
    width: 100%;
    height: 67px;
    margin: 10px 0px;
    padding-left: 10px;
    background: white;
    border-top: 1px solid #d3d9de;
    border-bottom: 1px solid #d3d9de;
    min-height: 67px;
    display: flex;
    align-items: center;
}

.gapOptionsImages{
    width: 100%;
    max-width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    button {
      padding: 0px;
      background: transparent;
      position: relative;
      width: 33.33%;
      .icon {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &.active {
        .icon {
          display: block;
        }
        &:before {
          content: '';
          position: absolute;
          width: calc(100% - 11px);
          height: calc(100% - 11px);
          top: 3px;
          left: 3px;
          background: transparent;
          border: solid 3px black;
          pointer-events: none;
          background: #0000003d;
        }
      }
    }
}

.buttonContent {
    display: flex;
}

.imageForCover {
  width: 100%;
  /* display: inline-table; */
  /* max-width: 240px; */
  /* min-width: 240px; */
  max-height: 180px;
  height: 100%;
  /* flex-basis: fit-content; */
  /* object-fit: inherit; */
  object-fit: cover;
  padding: 5px;
}