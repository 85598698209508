@import 'style';

.ActionMenu {
  position: relative;
  box-shadow: 0 2px 2px rgba($color-white, 0.129753);
  margin-top: 17px;
  margin-bottom: 17px;
  border-radius: 4px;
  border: 1px solid $color-regent-gray;
  padding: 13px 20px 13px 20px;
  cursor: default;
  background: $color-white;
}

.ActionMenu__list {
  display: flex;
  flex-direction: column;
}

.ActionMenu__item {
  display: flex;
}

.ActionMenu__item__disabled {
  color: #939FA7;
  cursor: default !important;
  margin: 7px;
  font-size: 0.9375rem;

  button {
    margin: 0;
  }
}

.ActionMenu__actionTarget {
  margin: 7px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  font-size: 0.9375rem;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  &:focus {
    outline: black;
    outline-style: auto;
  }
}

.ActionMenu__caret {
  position: absolute;
  top: -6px;
  right: 8px;
  margin-top: -12px;
  width: 12px;
  height: 24px;
  transform: rotate(-90deg);

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #fff;
    border-right: 1px solid $color-regent-gray;
    border-bottom: 1px solid $color-regent-gray;
  }
}

.ActionMenu__caret_bottom {
  top: auto;
  bottom: -6px;
  transform: rotate(90deg);

  &:after {
    transform: translate(50%, -50%) rotate(-45deg);
  }
}

@media screen and (max-width: $screen-dimension-desktop) {
  .ActionMenu {
    background: $color-text-primary;
    border-color: $color-text-primary;
    padding: 16px;
  }

  .ActionMenu__item__disabled {
    margin: 7px 0;
  }

  .ActionMenu__actionTarget {
    margin: 7px 0 7px 0;
    color: $color-white;
    font-size: 0.9375rem;
  }

  .ActionMenu__caret {
    &:after {
      background-color: $color-text-primary;
      border-color: $color-text-primary;
    }
  }
}
