@import "style";

.AssignmentsPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  .gradesChildrenItems {
    margin-top: 15px;
  }

  .NewAssignment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 4;

    .addAssignmentFlow {
      overflow: auto;
    }

    .newAssignmentPreview {
      background: #E7ECEF;
      border-left: 1px solid #d3d9de;

      .nothingPhrase {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 1.375rem;
      }

      .previewPhrase {
        width: 350px;
        text-align: center;
      }
    }
  }

  
  &.moveListBySearchFilter {
    transition: 0.3s;
    &.active {
      width: calc(100% - 290px);
      margin-left: 290px;
      .InfoCard {
        width: calc(20% - 15px);
      }
    }
  }

  .notFoundPage {
    .container-404 {
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  .assignmentList{
    height: 100%;
    overflow: auto;
  }
}

@media screen and (max-width: $screen-dimension-desktop) {

}

@media screen and (max-width: $screen-dimension-tablet) {
  .AssignmentsPage_noTabs {
    padding-top: 15px;
  }

  .assignmentList{
    height: 100%;

    .MyListPagination {
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 575px) {
  .AssignmentsPage {
    height: auto;
    .assignmentList {
      flex-flow: nowrap;
      width: calc(100% + 19px);
      .MyList {
        display: flex;
        flex-wrap: nowrap;
      }
      .AssignmentListItem__super {
        width: calc(100% - 10px);
        margin-right: 10px;
      }
    }
  }  
}