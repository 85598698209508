@import "../../../../style/index";

.Submit__title {
  margin-bottom: 32px;
  font-size: 2.25rem;
}

.AssignmentArticlesToReading {
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    z-index: -1;
    top: 0px;
  }
}

.Submit__delete {
  display: inline-block;
  margin-top: 20px;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.Submit__button {
  display: flex;
  align-items: center;
  padding: 7.5px 27px 7.5px 9px;
  margin-top: 32px;
  color: $color-white;
  background: $color-green;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.0625rem;
}

.Submit__description {
  font-size: 1.0625rem;
  font-weight: 300;
  max-width: 750px;
}

.Submit__image {
  margin-right: 15px;
}

.Submit__defaultCursor {
  cursor: default;
}

@media screen and (max-width: $screen-dimension-mobile-425) {
  .Submit__title {
    font-size: 1.5rem;
    margin-top: 60px;
    margin-bottom: 7px;
  }

  .Submit__button {
    margin-top: 60px;
    font-size: 0.875rem;
    padding-right: 25px;
  }
}
