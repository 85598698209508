.modalContentTGAssig {
  position: fixed;
  left: 50%;
  top: calc(40% - 0.59em);
  transform: translate(-50%, -28%);
  width: 700px;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
  z-index: 15;
  display: none;
  word-break: break-all;

  &__header {
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      padding: 25px 10px 20px 50px;

      h1 {
          text-transform: uppercase;
          color: #e2017b;
          font-size: 1rem;
          clear: both;
          margin-bottom:3px;
      }

      span {
          text-decoration: none !important;
          text-transform: none !important;
          color: #000 !important;
          font-size: 1rem !important;
          clear: both;
      }

      &__close {
        position:absolute;
        left: 98%;
        top: -15px;
        cursor: pointer;
        border-radius: 50%;
        .roundedCircle {
            fill: white;
        }
        &:hover {
            .roundedCircle {
                fill: rgb(211, 217, 222);
            }  
        }
    }
  }

  &__body {
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 25px 30px 0px 50px;
      max-height: 400px;
      overflow-y:auto;

      &__row {
          padding: 0px 0px;

          &.first {
              padding: 15px 0px;
          }

          h4 {
              display: flex;
              margin: 5px 0px;
              align-items: center;
              margin-bottom: 20px;
          }
      }

      .line {
          border-top: 1px solid #e9ecef;
          padding: 20px 0px;
      }

      .DescriptionEditor {
          width: 100% !important;
          font-size: 14px !important;
      }

      .nestedOrderNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 31px;
          height: 31px;
          min-height: 31px;
          background: #e2017b;
          border-radius: 50%;
          color: #ffffff;
          font-size: 1.0625rem;
          margin: 0 12px 0 0;
          padding: 0px 10px;
          border: 2px solid #e2017b;
          float: left;
      }
  }
  
  &__footer {
      display: flex;
      justify-content:space-between;
      padding: 20px 10px 25px 46px;
      border-top: 1px solid #e9ecef;

      &__aligLeft {
          display: flex;

          button {
            width: auto;
            padding: 0 23px;
            margin-top: auto;
            min-width: inherit;
          }
      }

      &__aligRight {
        margin-top: 9px;
        margin-right: 25px;

        button {
            display:flex;
            background: transparent;

            img {
                margin-left: 5px
            }
        }

        button.downloading {
            cursor: progress;
        img {
            visibility: hidden;
        }
        }
      }
  }
}
.modalContentTGAssig.open {
  display: block;
}
.horizontalLine {
  width: 135px;
  display: block;
  margin: 35px auto 30px auto;
  border-bottom: 1px solid #d3d9de;

  &.withOutButton {
      margin-top: 5px;
  }
}

.modalContentTGAssigBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .4;
}
.modalContentTGAssigBackground.hide {
  display: none;
}