@import "../../../../style";

$student-overview-header-height: 60px;
$student-overview-header-height-mobile: 51px;
$student-overview-user-panel-height: 63px;

.StudentOverview {
  width: 50vw;
  height: 100%;
  background: $color-light-gray;
}

.StudentOverview__header {
  display: flex;
  justify-content: space-between;
  height: $student-overview-header-height;
  background: $color-white;
  border-bottom: 1px solid $color-dark-gray;
  overflow-y: auto;

  &::-webkit-scrollbar:horizontal {
    display: none;
  }
}

.StudentOverview__userPanel {
  display: none;
}

.StudentOverview__headerBlock {
  display: flex;
  flex-shrink: 0;
}

.StudentOverview__tab {
  position: relative;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
  font-size: 0.9375rem;
  text-transform: uppercase;
  cursor: pointer;
  flex-shrink: 0;
}

.StudentOverview__tab_selected::after {
  display: block;
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  height: 4px;
  background-color: $color-purple;
}

.StudentOverview__content {
  height: calc(100% - #{$student-overview-header-height});
  background: $color-light-gray;
  padding: 20px;
  overflow: auto;
}

@media screen and (max-width: $screen-dimension-desktop) {
  .StudentOverview {
    width: 75vw;
  }
}

@media screen and (max-width: $screen-dimension-tablet) {
  .StudentsList__slideOutPanel {
    background: #e7ecef;
  }
  .StudentOverview {
    width: 100vw;
    height: auto;
    padding-top: 14px;
  }
  .StudentAssignments__list {
    height: auto;
    display: flex;
    width: calc(100% + 15px);
  }
  .StudentAssignments__item {
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .StudentOverview__header {
    height: $student-overview-header-height-mobile;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07);
  }

  .StudentOverview__tab {
    text-transform: none;
    padding: 15px 7px 0 7px;
  }

  .StudentOverview__tab_selected::after {
    left: 7px;
    right: 7px;
    height: 3px;
  }

  .StudentOverview__userPanel {
    height: $student-overview-user-panel-height;
    display: flex;
    align-items: center;
    padding: 13px 12px 12px 15px;
    background-color: $color-white;
    border-bottom: 1px solid $color-dark-gray;
  }

  .StudentOverview__userImage {
    @include circle(37px);
    object-fit: cover;
    flex-shrink: 0;
    margin-right: 11px;
  }

  .StudentOverview__userName {
    @include text-shrink;
    flex: 1 1;
  }

  .StudentOverview__closeButton {
    @include flex-center;
    padding: 0;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background-color: transparent;
  }

  .StudentOverview__closeButtonImage {
    width: 12px;
    height: 12px;
  }

  .StudentOverview__content {
    height: calc(100% - #{$student-overview-header-height-mobile + $student-overview-user-panel-height});
    background: $color-light-gray;
    padding: 19px 15px 20px 15px;
    overflow: auto;
  }
}
