.filenameSpan {
    margin-left: 10px !important;
}

.label {
    margin-bottom: 10px;
    display: block;
}

.imagesList {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
}

.imageListItem {
    /* position: absolute; */
    /* box-shadow: 0px 2px 4px #00000026;
    margin: 4px;
    padding: 18px;
    background-color: #d3d9de;
    border-radius: 5px;
    display: block; */
    box-shadow: 0px 2px 4px #00000026;
  
}

.icon {
    background-color: rgb(18, 11, 77);
    width: 25px;
    height: 25px;
    border-radius: 20px;
}

.filename {
    font-weight: 800;
    font-size: 1.0925rem;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.filesize {
    font-weight: 200;
    font-size: 1.0425rem;
}

.spaced {
    margin-bottom: 10px;
}

.previewImage {
    /* max-width: 100%;
    max-height: 250px; */
    max-width: 100%;
    min-height: 125px;
    max-height: 125px;
    object-fit: cover;
    background-size: cover;
}

.imageData {
    background: #AAAAAA;
    position: relative;
    z-index: 0;
}

.image {
    /* position: relative;
    width: 320px; */
    position: relative;
    width: calc(25%);
    min-width: calc(25%);
    padding: 10px;
}

.infoImage {
    background-color: #FFFFFF;
    padding: 12px;
}

.is-selectedImage-icon {
    cursor: pointer;
    position: relative;
    width: 100%;
    /* height: 100%; */
    /* min-width: 90px;
    min-height: 90px; */
    display: flex;
    right: 4px;
    justify-content: end;
    /* padding-left: 15px; */
    z-index: 1;
    /* justify-content: center;
    align-items: center; */
  }